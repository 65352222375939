import axios from "axios";
let BASE_URL = process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL : "";
export default {
  state: {
   allCourses: [],
   allMainCourses: [],
   courseData: [],
   courseClasses: [],
   currentClassSessionDetails: [],
   classDetails: [],
   classAccess: [],
   classBatches: [],
   currentBatch: [],
   batchRegistration: [],
   sessionRegistration: [],
   trainerData: [],
   availableAccess: [],
   quizData: [],
   skillData: [],
   sessionAttendanceData: [],
   curriculumCategoryData: [],
   curriculumCategoryChapters: [],
   chapterData: [],
   clubData: [],
   subcategoryQuestions: [],
   quizData: [],
   quizQuestions: [],
   genericCheckpoints: [],
   chapterLocalizationList: [],
   language_available: []
  },
  getters: {
    getAllCourses: (state) => state.allCourses,
    getAllMainCourses: (state) => state.allMainCourses,
    getCourseData: (state) => state.courseData,
    getCourseClasses: (state) => state.courseClasses,
    getCurrentClasseSessionDetails: (state) => state.currentClassSessionDetails,
    getClasseDetails: (state) => state.classDetails,
    getClassAccess: (state) => state.classAccess,
    getClassBatches: (state) => state.classBatches,
    getCurrentBatch: (state) => state.currentBatch,
    getBatchRegistrations: (state) => state.batchRegistration,
    getSessionRegistration: (state) => state.sessionRegistration,
    getTrainerData: (state) => state.trainerData,
    getAvailableAccess: (state) => state.availableAccess,
    getQuizData: (state) => state.quizData,
    getSkillData: (state) => state.skillData,
    getSessionAttendanceData: (state) => state.sessionAttendanceData,
    getCurriculumCategoryData: (state) => state.curriculumCategoryData,
    getCurriculumCategoryChapters: (state) => state.curriculumCategoryChapters,
    getChapterData: (state) => state.chapterData,
    getClubData: (state) => state.clubData,
    getSubcategoryQuestions: (state) => state.subcategoryQuestions,
    getAllQuiz: (state) => state.quizData,
    getQuizQuestions: (state) => state.quizQuestions,
    getGenericCheckpoints: (state) => state.genericCheckpoints,
    getChapterLocalizationList: (state) => state.chapterLocalizationList,
    getChapterLanguageAvailable: (state) => state.language_available,
  },
  actions: {
    fetchAllCourses({ commit}) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/curriculum/courses/All`).then((response) => {
          commit("setAllCourses",response.data.data)
          resolve("success")
        }).catch(error => {
          commit('setAllCourses', [])
          reject("error")
        })
      })
    },
    fetchAdminCourses({ commit}) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/curriculum/admin/course/listing`).then((response) => {
          commit("setAllCourses",response.data.data)
          resolve("success")
        }).catch(error => {
          commit('setAllCourses', [])
          reject("error")
        })
      })
    },
    fetchAllMainCourses({ commit}) {
      axios.get(`${BASE_URL}/curriculum/main-courses/All`).then((response) => {
        commit("setAllMainCourses",response.data.data)
      });
    },
    fetchDegreeCourses({ commit}, degreeCode){
      axios.get(`${BASE_URL}/curriculum/main-course/${degreeCode}`).then(response => {
        commit('setAllCourses',response.data.data.courseSkuData)
      }).catch(error => {
        commit('setAllCourses',[])
      })
    },
    fetchCourseDetails({ commit}, courseCode){
      axios.get(`${BASE_URL}/curriculum/course/codeDetails/${courseCode}`).then(response => {
        commit('setCourseData',response.data.data)
      }).catch(error => {
        commit('setCourseData',[])
      })
    },
    fetchCourseData({ }, courseCode){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/curriculum/course/codeDetails/${courseCode}`).then(response => {
          resolve(response.data.course_data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    fetchQuizDetails({ commit} ){
      axios.get(`${BASE_URL}/questions/quiz`).then(response => {
        commit('setQuizData',response.data.data)
      }).catch(error => {
        commit('setQuizData',[])
      })
    },
    fetchCourseClasses({ commit}, courseCode) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/lms/class/${courseCode}`).then((response) => {
          commit('setCourseClasses', response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          commit('setCourseClasses', [])
          resolve([])
        })
      })
    },
    addCourseClass({ dispatch }, classPayload){
        return new Promise((resolve,reject) => {
            axios.post(`${BASE_URL}/lms/class`,classPayload).then(response => {
                dispatch('fetchCourseClasses',classPayload.course_code)
                resolve("success")
            }).catch(error => {
                reject(error)
            })
        })
    },
    fetchCurrentClasseSessionDetails({ commit}, classPayload) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/lms/class/details/${classPayload.classCode}/${classPayload.sessionType}`).then((response) => {
          commit('setCurrentClasseSessionDetails', response.data.data)
          resolve("success")
        }).catch(error => {
          commit('setCurrentClasseSessionDetails', [])
          reject("error")
        })
      })
    },
    fetchClasseDetails({ commit}, classCode) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/lms/class/details/${classCode}`).then((response) => {
          resolve(response.data.data)
          commit('setClasseDetails', response.data.data)
        }).catch(error => {
          resolve([])
          commit('setClasseDetails', [])
        })
      })
    },
    addClassDetails({ dispatch }, classPayload){
        return new Promise((resolve,reject) => {
            axios.post(`${BASE_URL}/lms/class/details`,classPayload).then(response => {
                const currentClassPayload = {"classCode": classPayload.class_code,"sessionType": classPayload.session_type}
                dispatch('fetchCurrentClasseSessionDetails',currentClassPayload)
                resolve("success")
            }).catch(error => {
                reject(error)
            })
        })
    },
    addClassSessionDetails({ dispatch }, classPayload){
      return new Promise((resolve,reject) => {
          axios.put(`${BASE_URL}/lms/session/detail`,classPayload).then(response => {
              const currentClassPayload = {"classCode": classPayload.class_code,"sessionType": classPayload.session_type}
              dispatch('fetchCurrentClasseSessionDetails',currentClassPayload)
              resolve("success")
          }).catch(error => {
              reject(error)
          })
      })
  },
    fetchAllClassAccess({ commit }, classCodePayload){
      return new Promise((resolve,reject) => {
        if(classCodePayload.main_course_code == null)
          var url = `${BASE_URL}/lms/class/access/${classCodePayload.class_code}`
        else
          var url = `${BASE_URL}/lms/class/access/${classCodePayload.class_code}/${classCodePayload.main_course_code}`
        axios.get(url).then((response) => {
          commit('setClassAccess', response.data.data)
          resolve("success")
        }).catch(error => {
            commit('setClassAccess', [])
            reject("error")
        })
      })
    },
    addClassAccess({ dispatch }, accessPayload){
      return new Promise((resolve,reject) => {
          axios.post(`${BASE_URL}/lms/class/access`,accessPayload).then(response => {
              dispatch('fetchAllClassAccess',accessPayload)
              resolve("success")
          }).catch(error => {
              reject(error)
          })
      })
    },
    fetchAllClassBatch({ commit }, classCodePayload){
      return new Promise((resolve,reject) => {
        if(classCodePayload.main_course_code == null)
          var url = `${BASE_URL}/lms/class/batch/${classCodePayload.class_code}`
        else
          var url = `${BASE_URL}/lms/class/batch/${classCodePayload.class_code}/${classCodePayload.main_course_code}`
        axios.get(url).then((response) => {
          commit('setClassBatches', response.data.data)
          resolve("success")
        }).catch(error => {
            commit('setClassBatches', [])
            reject(error)
        })
      })
    },
    addClassBatch({ dispatch }, batchPayload){
      return new Promise((resolve,reject) => {
          axios.post(`${BASE_URL}/lms/class/batch`,batchPayload).then(response => {
              dispatch('fetchAllClassBatch',batchPayload)
              resolve("success")
          }).catch(error => {
              reject(error)
          })
      })
    },
    updateBatchAutomation({ dispatch }, batchAutomationPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/lms/batch/automation`,batchAutomationPayload).then(response => {
            dispatch('fetchAllClassBatch',batchAutomationPayload)
            resolve("success")
        }).catch(error => {
            reject(error)
        })
      })
    },
    fetchBatchDetails({ commit }, batchCode){
      axios.get(`${BASE_URL}/lms/batch/details/${batchCode}`).then((response) => {
        commit('setBatchDetails', response.data.data)
      }).catch(error => {
          commit('setBatchDetails', [])
      })
    },
    addExtraSession({dispatch }, batchCode){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/lms/batch/extra/session`,{"batch_code" : batchCode}).then((response) => {
          dispatch('fetchBatchDetails',batchCode)
          resolve("success")
        }).catch(error => {
          reject(error)
        })
      })
    },
    fetchBatchRegistrations({ commit }, batchCode){
      axios.get(`${BASE_URL}/lms/batch/registrations/${batchCode}`).then((response) => {
        commit('setBatchRegistrations', response.data.data)
      }).catch(error => {
          commit('setBatchRegistrations', [])
      })
    },
    fetchCurrentSessionRegistration({ commit}, batchPayload) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/lms/batch/session/${batchPayload.batch_id}/${batchPayload.session_number}`).then((response) => {
            commit('setCurrentSessionRegistration', response.data.data)
            resolve("success")
        }).catch(error => {
            commit('setCurrentSessionRegistration', [])
            reject("error")
        })
      })
    },
    fetchTrainers({ commit} ) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/lms/get/trainers`).then((response) => {
          commit('setTrainersData', response.data.data)
          resolve("success")
        }).catch(error => {
          commit('setTrainersData', [])
          reject("error")
        })
      })
    },
    createBatchScheduling({ }, schedulePayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/lms/class/batch/scheduling`,schedulePayload).then(response => {
            resolve("success")
        }).catch(error => {
            reject(error)
        })
      })
    },
    revokeSession({ }, sessionPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/lms/session/revoke`,sessionPayload).then(response => {
            resolve("success")
        }).catch(error => {
            reject(error)
        })
      })
    },
    markSessionCompleted({ }, sessionPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/lms/session/completed`,sessionPayload).then(response => {
            resolve("success")
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    markSessionStudentNotJoined({ }, sessionPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/lms/session/status`,sessionPayload).then(response => {
            resolve("success")
        }).catch(error => {
            reject(error)
        })
      })
    },
    revokeUpcomingSession({ }, sessionPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/lms/upcoming/session/revoke`,sessionPayload).then(response => {
            resolve("success")
        }).catch(error => {
            reject(error)
        })
      })
    },
    fetchAvailableRegistration({ commit }, batchCodePayload){
      if(batchCodePayload.main_course_code == null)
        var url = `${BASE_URL}/lms/available/student/${batchCodePayload.batch_code}`
      else
        var url = `${BASE_URL}/lms/available/student/${batchCodePayload.batch_code}/${batchCodePayload.main_course_code}`
      axios.get(url).then((response) => {
        commit('setAvailableStudents', response.data.data)
      }).catch(error => {
          commit('setAvailableStudents', [])
      })
    },
    addBatchRegistration({ dispatch }, registerPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/lms/class/batch/registration`,registerPayload).then(response => {
            dispatch('fetchBatchRegistrations',registerPayload.batch_code)
            resolve("success")
        }).catch(error => {
            reject(error)
        })
      })
    },
    revokeBatchRegistration({ }, registerPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/lms/batch/registration/revoke`,registerPayload).then(response => {
            resolve("success")
        }).catch(error => {
            reject(error)
        })
      })
    },
    updateClassAccess({ }, accessPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/lms/update/class/access`,accessPayload).then(response => {
            resolve("success")
        }).catch(error => {
            reject(error)
        })
      })
    },
    toogleFullAccess({ }, accessPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/lms/update/full/access`,accessPayload).then(response => {
            resolve("success")
        }).catch(error => {
            reject(error)
        })
      })
    },
    updateBatchTa({ }, taPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/lms/batch/ta`,taPayload).then(response => {
            resolve("success")
        }).catch(error => {
            reject(error)
        })
      })
    },
    updateBatchStatus({ }, batchPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/lms/update/batch/status`,batchPayload).then(response => {
            resolve("success")
        }).catch(error => {
            reject(error)
        })
      })
    },
    removeClassDetail({ dispatch }, classPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/lms/remove/class/detail`, classPayload).then(response => {
          dispatch("fetchCurrentClasseSessionDetails",classPayload)
          resolve("success")
        }).catch(error => {
          reject(error)
        })
      })
    },
    fetchSkills({ commit }){
      axios.get(`${BASE_URL}/curriculum/skills/All`).then((response) => {
        commit("setSkillData",response.data.data)
      });
    },
    fetchNewSkills({ commit }){
      axios.get(`${BASE_URL}/curriculum/new-skills/All`).then((response) => {
        commit("setSkillData",response.data.data)
      });
    },
    fetchBatchSessionAttendance({ commit }, attendancePayload){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/lms/batch/session/attendance/${attendancePayload.batch_code}/${attendancePayload.session_id}`).then((result) => {
          commit("setSessionAttendance",result.data.data)
          resolve(result.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    markSessionAttendance({ dispatch }, attendancePayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/lms/update/session/attendance`,attendancePayload).then(response => {
            dispatch("fetchBatchSessionAttendance",attendancePayload)
            resolve("success")
        }).catch(error => {
            reject(error)
        })
      })
    },
    fetchCurriculumCategories({ commit }){
      axios.get(`${BASE_URL}/curriculum`).then((response) => {
        commit("setCurriculumCategories",response.data.data)
      })
    },
    fetchChaptersBySubCategory({ commit }, subCategoryUri){
      axios.get(`${BASE_URL}/curriculum/chapters/subcategories/${subCategoryUri}`).then((response) => {
        commit("setCurriculumCategoryChapters",response.data.data)
      })
    },
    fetchChapterData({ commit }, chapterPayload){
      axios.get(`${BASE_URL}/curriculum/new/chapters/${chapterPayload.subcategory_uri}/${chapterPayload.chapter_code}`).then((response) => {
        commit("setChapterData",response.data.data)
      })
    },
    addChapterData({ dispatch }, chapterPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/curriculum/new/lms/chapter`,chapterPayload).then(response => {
          dispatch("fetchChaptersBySubCategory",chapterPayload.subcategory_uri)
          resolve("success")
        }).catch(error => {
            reject(error)
        })
      })
    },
    updateChapterData({ dispatch }, chapterPayload){
      return new Promise((resolve,reject) => {
        if(chapterPayload.subcategory_uri){
          axios.put(`${BASE_URL}/curriculum/new/lms/chapter`,chapterPayload).then(response => {
            dispatch("fetchChapterData",chapterPayload)
            resolve("success")
          }).catch(error => {
              reject(error)
          })
        }else{
          axios.post(`${BASE_URL}/curriculum/chapter/localization`,chapterPayload).then(response => {
            dispatch("fetchLocalizeChapterData",chapterPayload)
            resolve("success")
          }).catch(error => {
              reject(error)
          })
        }
      })
    },
    fetchClassClubData({ commit }, class_code){
      axios.get(`${BASE_URL}/lms/club/listing/${class_code}`).then((response) => {
        commit("setClassClubData",response.data.data)
      })
    },
    addClassClubData({ dispatch }, clubPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/lms/club`,clubPayload).then(response => {
          dispatch("fetchClassClubData",clubPayload.class_code)
          resolve("success")
        }).catch(error => {
            reject(error)
        })
      })
    },
    getCourseSkillByBatch({ }, batch_code){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/lms/skill/batch/${batch_code}`).then(response => {
          resolve(response.data.data)
        }).catch(error => {
            reject(error)
        })
      })
    },
    getReportCard({ }, reportPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/lms/get/report/card`,reportPayload).then(response => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    saveReportCard({ }, reportPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/lms/save/report/card`,reportPayload).then(response => {
          resolve("success")
        }).catch(error => {
          reject(error)
        })
      })
    },
    getFeedbackDetails({ }, feedbackPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/lms/get/feedback`,feedbackPayload).then(response => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSessionCompletionFeedbackDetails({ }, feedbackPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/lms/get/session/completion/feedback`,feedbackPayload).then(response => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addQuestion({ dispatch}, questionPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/questions`,questionPayload).then(response => {
          dispatch("fetchQuestionBySubcategory",questionPayload.sub_category)
          resolve("success")
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    addQuiz({ dispatch}, quizPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/questions/quiz`,quizPayload).then(response => {
          dispatch("getQuizData",quizPayload.quiz_id)
          resolve("success")
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchQuestionBySubcategory({ commit }, sub_category){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/questions/questionsBySubcategory/${sub_category}`).then((response) => {
          resolve(response.data.data)
          commit("setSubcategoryQuestions",response.data.data)
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    fetchAllQuiz({ commit }){
      axios.get(`${BASE_URL}/questions/quiz`).then((response) => {
        commit("setALLQuiz",response.data.data)
      }).catch(error => {
        console.log(error)
      })
    },
    getQuizData({ commit }, quizId){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/questions/quizId/${quizId}`).then(response => {
          resolve(response.data.data)
          commit("setQuizQuestions", response.data.data)
        }).catch(error => {
          commit("setQuizQuestions", [])
          reject(error)
        })
      })
    },
    fetchGenericCheckpoints({ commit }){
      axios.get(`${BASE_URL}/curriculum/generic/checkpoints`).then((response) => {
        commit("setGenericCheckpoints",response.data.data)
      });
    },
    addGenericCheckPoint({ dispatch}, checkpointPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/curriculum/generic/checkpoint`,checkpointPayload).then(response => {
          dispatch("fetchGenericCheckpoints")
          resolve("success")
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchChapterLocalizationList({ commit }, chapter_code){
      axios.get(`${BASE_URL}/curriculum/chapter/localization/list/${chapter_code}`).then((response) => {
        commit("setChapterLocalization",response.data.data)
        commit("setChapterLanguageAvailable",response.data.language_available)
      });
    },
    createChapterLanguage({ dispatch}, chapterPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/curriculum/chapter/localization`,chapterPayload).then(response => {
          dispatch("fetchChapterLocalizationList",chapterPayload.chapter_code)
          resolve("success")
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchLocalizeChapterData({ commit }, chapterPayload){
      axios.get(`${BASE_URL}/curriculum/localize/chapter/data/${chapterPayload.language}/${chapterPayload.chapter_code}`).then((response) => {
        commit("setChapterData",response.data.data)
      })
    },
    updateClassShareable({ }, classPayload){
      return new Promise((resolve,reject) => {
          axios.put(`${BASE_URL}/lms/update/class/shareable`,classPayload).then(response => {
              resolve("success")
          }).catch(error => {
              reject(error)
          })
      })
    },
    addCourse({}, coursePayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/curriculum/courses`,{"course_data" : coursePayload}).then(response => {
          resolve("success")
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    updateLmsClass({ }, coursePayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/curriculum/update/lms/class`, coursePayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getUserSubmissionByAccessCode({ }, accessCode){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/lms/user/submission/${accessCode}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getCourseAnalytics({ }, courseCode){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/lms/course/analytics/${courseCode}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSessionSchedule({ }, sessionPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/lms/session/schedule/analytics`,sessionPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSessionAttendanceAnalytics({ }, sessionPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/lms/session/attendance/analytics`,sessionPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSessionReschedule({ }, sessionPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/lms/session/reschedule/analytics`,sessionPayload).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSessionPendingFeedback({ }, sessionPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/lms/session/pending/feedback`,sessionPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateDefaultBatch({ }, batchPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/lms/class/default/batch`,batchPayload).then(response => {
            resolve("success")
        }).catch(error => {
            reject(error)
        })
      })
    },
    batchCertificateAssign({ }, batchPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/lms/batch/certificate/assign`,batchPayload).then(response => {
            resolve(response.data.data)
        }).catch(error => {
            reject(error)
        })
      })
    },
    addCourseCertificate({ }, batchPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/lms/add/course/certificate`,batchPayload).then(response => {
            resolve(response.data.data)
        }).catch(error => {
            reject(error)
        })
      })
    },
    getLmsUsageStats({ }, lmsPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/lms/usage/stats`,lmsPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    courseEndingSession({ }, lmsPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/lms/course/ending`,lmsPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  mutations: {
    setAllCourses: (state, coursesData) => (state.allCourses = coursesData),
    setAllMainCourses: (state, mainCourseData) =>(state.allMainCourses = mainCourseData),
    setCourseData: (state, courseData) => (state.courseData = courseData),
    setCourseClasses: (state, classData) => (state.courseClasses = classData),
    setCurrentClasseSessionDetails: (state, classData) => (state.currentClassSessionDetails = classData),
    setClasseDetails: (state, classData) => (state.classDetails = classData),
    setClassAccess: (state, accessData) => (state.classAccess = accessData),
    setClassBatches: (state, batchData) => (state.classBatches = batchData),
    setBatchDetails: (state, batchData) => (state.currentBatch = batchData),
    setBatchRegistrations: (state, batchRegistration) => (state.batchRegistration = batchRegistration),
    setCurrentSessionRegistration: (state, sessionRegistration) => (state.sessionRegistration = sessionRegistration),
    setTrainersData: (state, trainerData) => (state.trainerData = trainerData),
    setAvailableStudents: (state, accessData) => (state.availableAccess = accessData),
    setQuizData: (state, quizData) => (state.quizData = quizData),
    setSkillData: (state, skillData) => (state.skillData = skillData),
    setSessionAttendance: (state, attendanceData) => (state.sessionAttendanceData = attendanceData),
    setCurriculumCategories: (state, curriculumCategoryData) => (state.curriculumCategoryData = curriculumCategoryData),
    setCurriculumCategoryChapters: (state, curriculumCategoryChapters) => (state.curriculumCategoryChapters = curriculumCategoryChapters),
    setChapterData: (state, chapterData) => (state.chapterData = chapterData),
    setClassClubData: (state, clubData) => (state.clubData = clubData),
    setSubcategoryQuestions: (state, subcategoryQuestions) => (state.subcategoryQuestions = subcategoryQuestions),
    setALLQuiz: (state, quiz) => (state.quizData = quiz),
    setQuizQuestions: (state, quizQuestions) => (state.quizQuestions = quizQuestions),
    setGenericCheckpoints: (state, genericCheckpoints) => (state.genericCheckpoints = genericCheckpoints),
    setChapterLocalization: (state, chapterList) => (state.chapterLocalizationList = chapterList),
    setChapterLanguageAvailable: (state, language_available) => (state.language_available = language_available)
  },
};
