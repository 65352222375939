import { macAddress } from "@vuelidate/validators";
import axios from "axios";
let BASE_URL = process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL : "";
export default {
  state: {
    serialPort: "",
    macAddress: "",
    bluetoothName: "",
    brainName: "",
    epochTimeStamp: "",
    amsPlayCategories: [],
    amsPlayCategoryData: [],
    inventoryOrderId: false
  },
  getters: {
    getMacAddress: (state) => state.macAddress,
    getbluetoothName: (state) => state.bluetoothName,
    getBrainName: (state) => state.brainName,
    getAmsPlayCategories: (state) => state.amsPlayCategories,
    getAmsPlayCategoryData: (state) => state.amsPlayCategoryData
  },
  actions: {
    fetchUserPrograms({ }){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/programs/getUser`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    fetchChapterPrograms({ },chapter_code){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/programs/curriculum/program/${chapter_code}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    saveCurriculumprogram({ }, programPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/programs/curriculum/program`,programPayload).then((response) => {
          resolve("success")
        }).catch(error => {
          reject(error)
        })
      })
    },
    fetchGetMacprogram({ state}) {
        return new Promise((resolve,reject) => {
          const brainName = state.brainName
          axios.get(`${BASE_URL}/programs/getMac/${brainName}`).then((response) => {
            resolve(response.data.data)
          });
        })
    },
    fetchSetEpochprogram({state}) {
      return new Promise((resolve,reject) => {
        try{
          const brainName = state.brainName
          axios.get(`${BASE_URL}/programs/setEpoch/${brainName}`).then((response) => {
            resolve(response.data)
          });
        }catch(error){
          console.log(error)
          reject(error)
        }
      })
    },
    fetchWelcomeprogram({state}) {
      return new Promise((resolve,reject) => {
        try{
          const brainName = state.brainName
          axios.get(`${BASE_URL}/programs/setWelcomeCode/${brainName}`).then((response) => {
            resolve(response.data)
          });
        }catch(error){
          console.log(error)
          reject(error)
        }
      })
    },
    fetchTestingProgram({state}) {
      return new Promise((resolve,reject) => {
        try{
          const brainName = state.brainName
          axios.get(`${BASE_URL}/programs/setTestingCode/${brainName}`).then((response) => {
            resolve(response.data)
          });
        }catch(error){
          console.log(error)
          reject(error)
        }
      })
    },
    fetchSensorTestingProgram({ state},sensorName) {
      return new Promise((resolve,reject) => {
        const brainName = state.brainName
        axios.get(`${BASE_URL}/programs/sensor/testing/${brainName}/${sensorName}`).then((response) => {
          resolve(response.data)
        });
      })
    },
    addBrainTestCase({state},testCases){
      return new Promise((resolve,reject) => {
        try{
          const brainName = state.brainName
          const macAddress = state.macAddress
          const inventoryOrderId = state.inventoryOrderId
          const testCasePayload = { brainName,macAddress,testCases,inventoryOrderId }
          axios.post(`${BASE_URL}/programs/brain/testing`,testCasePayload).then((response) => {
            resolve(response.data)
          }).catch(err => {
            reject(err)
          })
        }catch(error){
          console.log(error)
          reject(error)
        }
      })
    },
    checkBrainMac({state},payload){
      return new Promise((resolve,reject) => {
        try{
          const brainName = state.brainName
          const macAddress = state.macAddress
          const inventoryOrderId = state.inventoryOrderId
         
          axios.post(`${BASE_URL}/programs/check/brain/mac`,{brainName,macAddress,inventoryOrderId,type: payload.type}).then((response) => {
            resolve(response.data)
          }).catch(err => {
            reject(err.response.data)
          })
        }catch(error){
          console.log(error)
          reject(error)
        }
      })
    },
    createEpochKey({state}){
      return new Promise((resolve,reject) => {
        try{
          const brainName = state.brainName
          const macAddress = state.macAddress
          const epochTimeStamp = state.epochTimeStamp
          const inventoryOrderId = state.inventoryOrderId
          const epochPayload = { brainName,macAddress,epochTimeStamp,inventoryOrderId }
         
          axios.post(`${BASE_URL}/programs/brain/epoch`,epochPayload).then((response) => {
            resolve(response.data.url)
          }).catch(err => {
            reject(err.response.data)
          })
        }catch(error){
          console.log(error)
          reject(error)
        }
      })
    },
    // async sleep(ms) {
    //   console.log("sleep12")
    //     return await new Promise((resolve) => setTimeout(resolve, ms));
    // },
    writeData({state}, data) {
      return new Promise(async (resolve,reject) => {
        try{
          const serialPort = state.serialPort
          const encoder = new TextEncoder();
          const writer = serialPort.writable.getWriter()
          let toWrite = encoder.encode(data);
          await writer.write(toWrite);
          writer.releaseLock();
          console.log("write success")
          resolve("success")
        }catch(error){
          reject(error)
        }
      })
    },
    resetBrain({ dispatch,state }) {
      return new Promise(async (resolve,reject) => {
        try{
          const serialPort = state.serialPort
          //await dispatch("sleep",500);
          await new Promise((resolve) => setTimeout(resolve, 500));
          await serialPort.setSignals({ dataTerminalReady: false });
          await serialPort.setSignals({ requestToSend: false });
         // await dispatch("sleep",1000);
          await new Promise((resolve) => setTimeout(resolve, 1000));
          await serialPort.setSignals({ dataTerminalReady: true });
          await serialPort.setSignals({ requestToSend: false });
          await serialPort.setSignals({ dataTerminalReady: true });
          //await dispatch("sleep",100);
          await new Promise((resolve) => setTimeout(resolve, 100));
          await serialPort.setSignals({ dataTerminalReady: false });
          await serialPort.setSignals({ requestToSend: true });
          await serialPort.setSignals({ dataTerminalReady: false });
         // await dispatch("sleep",50);
          await new Promise((resolve) => setTimeout(resolve, 50));
          await serialPort.setSignals({ dataTerminalReady: false });
          await serialPort.setSignals({ requestToSend: false });
          await serialPort.setSignals({ dataTerminalReady: false });
          console.log("RESET BRAIN COMPLETE");
          resolve("RESET BRAIN COMPLETE")
        }catch(error){
          reject(error)
        }
      })
    },
    readMAC({ commit,state }) {
      return new Promise(async (resolve,reject) => {
          class LineBreakTransformer {
              constructor() {
                  this.container = '';
              }
          
              transform(chunk, controller) {
                  if(!chunk.includes('raw REPL') && !chunk.includes('<')) {
                      this.container += chunk;
                      const lines = this.container.split('\r\n');
                      this.container = lines.pop();
                      lines.forEach(line => controller.enqueue(line));
                  }
              }
          
              flush(controller) {
                  controller.enqueue(this.container);
              }
          }
          const serialPort = state.serialPort
          while (serialPort.readable) {
              const reader = serialPort.readable
              .pipeThrough(new TextDecoderStream())
              .pipeThrough(new TransformStream(new LineBreakTransformer()))
              .getReader();
              try {
                  while (true) {
                      const { value, done } = await reader.read();
                      console.log(value)
                      if (done) {
                          break;
                      }
                      if (value.length === 17) {
                        const macAddress = value.replaceAll(":","")
                        let bluetoothName = ""
                        if(state.brainName == "FULL 2.0 BLE"){
                          bluetoothName = `FB_${macAddress.slice(-6)}`
                        }else if(state.brainName  == "LITE 2.0 BLE"){
                          bluetoothName = `LB_${macAddress.slice(-6)}`
                        }else if(state.brainName  == "MEXcore"){
                          bluetoothName = `MEX_${macAddress.slice(-6)}`
                        }else if(state.brainName  == "MEXcore+"){
                          bluetoothName = `MEX_${macAddress.slice(-6)}`
                        }else if(state.brainName  == "Maker Board 2.0"){
                          bluetoothName = `MB_${macAddress.slice(-6)}`
                        }else if(state.brainName = "TWEAK 2.0"){
                          bluetoothName = `TK_${macAddress.slice(-6)}`
                        }
                        commit("setMacAddress",macAddress)
                        commit("setBluetoothName",bluetoothName)
                        break;
                      }
                  }
              } catch (error) {
                  console.error('READ DATA ERROR: ', error);
                  reject(error)
              } finally {
                  await reader.releaseLock();
                  break;
              }
          }
          resolve("Success")
      })
    },
    transferCode({ dispatch }, transferPayload) {
      return new Promise(async (resolve,reject) => {
        try{
          let userCode = transferPayload.program;
          console.log(userCode)
          userCode = userCode.split('\n').join('\\n');
          userCode = userCode.split(`'`).join(`"`);
          const script1 = `f = open('${transferPayload.fileName}', 'wb')\r\n`;
          const script2 = `f.write(b'`;
          const script3 = `')\r\n`;
          const script4 = `f.close()\r\n`;
          await dispatch("writeData",`\x03`)
          await new Promise((resolve) => setTimeout(resolve, 500));
          await dispatch("writeData",`\x01`)
          await new Promise((resolve) => setTimeout(resolve, 500));
          await dispatch("writeData",script1)
          await new Promise((resolve) => setTimeout(resolve, 500));
          await dispatch("writeData",script2)
          await new Promise((resolve) => setTimeout(resolve, 500));
          await dispatch("writeData",userCode)
          await new Promise((resolve) => setTimeout(resolve, 500));
          await dispatch("writeData",script3)
          await new Promise((resolve) => setTimeout(resolve, 500));
          await dispatch("writeData",script4)
          await new Promise((resolve) => setTimeout(resolve, 500));
          await dispatch("writeData",`\x04`)
          await new Promise((resolve) => setTimeout(resolve, 500));
          await dispatch("writeData",`\x02`)
          await new Promise((resolve) => setTimeout(resolve, 500));
          console.log("TRANSFER CODE COMPLETE");  
          resolve("TRANSFER CODE COMPLETE")
        }catch(error){
          reject(error)
        }
      })
    },
    async getBoardInfo({ dispatch,commit,state }) {
      const script = 'import uos\r\nprint()\r\nprint(uos.uname())\r\n';
      await dispatch("writeData",`\x03`)
      await new Promise((resolve) => setTimeout(resolve, 500));
      await dispatch("writeData",`\x01`)
      await new Promise((resolve) => setTimeout(resolve, 500));
      await dispatch("writeData",script)
      await new Promise((resolve) => setTimeout(resolve, 500));
      await dispatch("writeData",`\x04`)
      await new Promise((resolve) => setTimeout(resolve, 500));
      await dispatch("writeData",`\x02`)
      await new Promise((resolve) => setTimeout(resolve, 500));

      class LineBreakTransformer {
        constructor() {
            this.container = '';
        }
    
        transform(chunk, controller) {
            //if(!chunk.includes('raw REPL') && !chunk.includes('<')) {
                this.container += chunk;
                const lines = this.container.split('\r\n');
                this.container = lines.pop();
                lines.forEach(line => controller.enqueue(line));
           // }
        }
    
        flush(controller) {
            controller.enqueue(this.container);
        }
      }

      const serialPort = state.serialPort
      while (serialPort.readable) {
          const reader = serialPort.readable
          .pipeThrough(new TextDecoderStream())
          .pipeThrough(new TransformStream(new LineBreakTransformer()))
          .getReader();
          try {
              while (true) {
                  const { value, done } = await reader.read();
                  if (done) {
                      break;
                  }
                  if(value.includes('sysname')) {
                      let arr = value.split("(")[1].split(")")[0].split(",")
                      arr = arr.map((a) => {
                        let b = a.trim().split("=")
                        let c = `{ "${b[0]}":"${b[1].replace(/\'/g, "")}" }`
                        return JSON.parse(c)
                      })
                      let boardInfo = {}
                      arr.map((i)=> boardInfo[Object.keys(i)[0]] = i[Object.keys(i)[0]])
                      let boardName = boardInfo.machine;
                      if (boardName === 'FULL 2.0') {
                        boardName = 'FULL 2.0 BLE'
                      } else if (boardName === 'LITE 2.0') {
                        boardName ='LITE 2.0 BLE'
                      } else if (boardName === 'MKB 2.0') {
                        boardName = 'Maker Board 2.0'
                      }
                      commit("setBrainname",boardName)
                      console.log(boardInfo);
                      break;
                  }
              }
          } catch (error) {
              console.error('GET BOARDINFO ERROR: ', error);
          } finally {
              await reader.cancel();
              break;
          }
      }
    },
    fetchAmsPlayCategory({commit}, type){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/programs/ams/play/category/${type}`).then((response) => {
          commit("setAmsPlayCategory",response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchAmsPlayCategoryDetails({commit}, category_id){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/programs/ams/play/details/${category_id}`).then((response) => {
          commit("setAmsPlayCategoryData",response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    addAmsPlayCategory({dispatch}, amsPlayPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/programs/ams/play/category`,amsPlayPayload).then((response) => {
          dispatch("fetchAmsPlayCategory",amsPlayPayload.type)
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    uploadCategoryImage({}, imagePayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/programs/upload/ams/play/category/image`,imagePayload).then(async (response) => {
          await axios.put(response.data.url, imagePayload.file, { headers: {'Content-Type': imagePayload.file.type} })
          console.log(response.data.url)
          resolve(response.data.url.split("?Content-Type=")[0])
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    addAmsPlayCategoryDetail({}, categoryPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/programs/ams/play/category/detail`,categoryPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    removeAmsPlayCategoryDetail({}, categoryPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/programs/delete/ams/play/category/data`,categoryPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
  },
  mutations: {
    setSerialPort: (state, serialPort) => (state.serialPort = serialPort),
    setEpochTime: (state, epochTimeStamp) => (state.epochTimeStamp = epochTimeStamp),
    setMacAddress: (state, macAddress) => (state.macAddress = macAddress),
    setBluetoothName: (state, bluetoothName) => (state.bluetoothName = bluetoothName),
    setBrainname: (state, brainName) => (state.brainName = brainName),
    setAmsPlayCategory: (state, amsPlayCategories) => (state.amsPlayCategories = amsPlayCategories),
    setAmsPlayCategoryData: (state, amsPlayCategoryData) => (state.amsPlayCategoryData = amsPlayCategoryData),
    setInventoryOrderId: (state, inventoryOrderId) => (state.inventoryOrderId = inventoryOrderId)
  },
};
